import React from "react";

const Home = () => {
    return (
        <div 
            style={{ 
                color: '#0dcaf0', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                textAlign: 'center' 
            }} 
        >
            <h1>We are Coming Soon....</h1>
        </div>
    );
}

export default Home;
